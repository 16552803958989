import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ForgetPassword.css";
import { useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { Button } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Pages/Loader";
import logo from "../../Assets/logo2.png";

function ForgetPassword({language}) {

  const [user, setUser] = useState({ email: ""});

  const [loading, setLoading] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

 const resetPassword = language === "ar" ? "إعادة تعيين كلمة السر" : "Reset Password"

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/send-code`, user)
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setUser({ email: "" });
        navigate("/new-passowrd")
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });
  };

  return (
<>
{loading ? <Loader/> :
<Container className="parent-login">
<div className="login">
<Row>

<Col md={6} className="logo-back d-flex align-items-center justify-content-center">
<img className='logo' src={logo} alt='' />
</Col>

<Col md={6}>
<div className="login-sec">
            <h5> {language === "ar" ? "استعادة كلمة السر" : "Password Recovery"} </h5>
            <p> {language === "ar" ? "سوف نرسل لك الكود في بريدك الإلكتروني" : "We will send you the code in your email"} </p>
            <form
              className="d-flex flex-column align-items-center"
              onSubmit={handleSubmit}
            >
              <div className="form-input">
                <span>
                  <MdEmail />
                </span>

                <input
                  type="email"
                  placeholder= {language === "ar" ? "البريد الالكتروني" : "Email"}
                  value={user.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
              <Button
                htmlType="submit"
                loading={submitting}
                className="btn-login mx-auto mt-4"
              >
                {!submitting && resetPassword}
              </Button>
            </form>
          </div>
</Col>
</Row>

</div>
</Container>
}
</>

  );
}
export default ForgetPassword
