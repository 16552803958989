import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Parse from "html-react-parser";
import Loader from './Loader';


function Privacy({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
        try {
            setLoading(true);
            const wordsResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/privacy`,
                headers: {
                    Accept: "application/json",
                    lang: language
                },
            });
            const mainResult = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/pages/privacy`,
              headers: {
                Accept: "application/json",
                lang: language
              },
            });
          setMainData(mainResult.data.data);
            setWordsData(wordsResult.data.data);
            setLoading(false);
        } catch (err) {
        }
    })();
}, [language]);

  return (
    <>
         {loading ? <Loader/> :
    <div className='privacy'>
         <div className='bg-head' style={{ backgroundImage: `url(${mainData?.images?.main_image})`}}>
<h5 className='bg-title'> {mainData?.words?.privacy} </h5>
      </div>
      <Container className='py-4'>
      {loading ? <Loader/> :
       <>
      {Parse(`${wordsData?.privacy}`)}
      </>
  }
      </Container>
    </div>
}
</>
  )
}

export default Privacy
