import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container } from 'react-bootstrap';
import Loader from './Loader';
import { useParams } from 'react-router-dom';
import Parse from 'html-react-parser';
import ExampleComponent from '../Components/Example/Example';

export default function BlogDetails({language}) {

  
  const [wordsData, setWordsData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
      (async () => {
          try {
              setLoading(true);
              const wordsResult = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/blogs/${id}`,
                  headers: {
                      Accept: "application/json",
                      lang: language
                  },
              });
              const mainResult = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/pages/services`,
                  headers: {
                      Accept: "application/json",
                      lang: language
                  },
              });
              setMainData(mainResult.data.data);
              setWordsData(wordsResult.data.data);
              setLoading(false);
          } catch (err) {
          }
      })();
  }, [language]);


  return (
    <>
            {loading ? <Loader /> :
                <div className='service-details'>
                    <div className='bg-head' style={{ backgroundImage: `url(${mainData?.images?.main_image})` }}>
                        <h5 className='bg-title'> {language === "ar" ? "المدونة" : "BLOGS"} </h5>
                    </div>

                    <Container className='py-4'>
                        <Col md={12}>
                        <ExampleComponent>
                        <h6 className='text-center'>{wordsData.title}</h6>
                            <img className='words-data-img' src={wordsData.image} />
                            <div className='serv-details-content'>
                                {Parse(`${wordsData.description}`)}
                            </div>
                            </ExampleComponent>
                        </Col>
                    </Container>
                </div>
            }
        </>
  )
}
