import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import "./BasicModal.css"
import { Button } from "antd";
import { toast } from "react-toastify";
import axios from 'axios';


function BasicModal({show ,handleClose ,wordsData ,language}) {

  const [comment, setComment] = useState({ name: "", opinion: "" });
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setComment({ ...comment, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "Tajawal" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "Tajawal" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };


const handleConfirm = () => {
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/send-opinion`, comment)
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setComment({ name: "", opinion: "" }); 
        handleClose();   
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });
  };


  return (
    <>
    <Modal show={show} onHide={handleClose} centered style={{direction : language === "en" ? "ltr" : "rtl"}}>
      <Modal.Header closeButton>
        <Modal.Title> {wordsData?.words?.Add_your_comment_popup} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="comment-sec p-2 mb-2">
                <div className="d-flex flex-column align-items-center">
                <input
                  type="text"
                  placeholder={wordsData?.words?.name_of_comment_popup}
                  value={comment.name}
                  name="name"
                  onChange={handleChange}
                />
                  <textarea
                    className="form-control ml-1 shadow-none textarea mx-4 py-4 mt-3"
                    value={comment.opinion}
                     name="opinion"
                    onChange={handleChange}
                    placeholder={wordsData?.words?.comment_of_comment_popup}
                  ></textarea>
                </div>
              </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='cancel-btn'  onClick={handleClose}>
        {wordsData?.words?.cancel_of_comment_popup}
        </button>
        <Button
                htmlType="submit"
                loading={submitting}
                onClick={handleConfirm}
                className="add-btn"
              >
                {!submitting && wordsData?.words?.send_of_comment_popup}
              </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default BasicModal
