import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoLocation } from "react-icons/io5";
import { FaEnvelope } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import ExampleComponent from '../Example/Example';
import axios from 'axios';

function Location({language}) {

    const [wordsData, setWordsData] = useState([]);
const [loading, setLoading] = useState(false);


useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/contact_section`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        setWordsData(wordsResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);

    return (
        <Container>
            <ExampleComponent>

                <Row className='gx-0 align-items-stretch my-5'>
                    <Col md={6} className='d-flex flex-column'>
                        <div className='contact-info'>
                            <h6> {wordsData?.words?.have_question} </h6>
                            <div className='d-flex my-4'>
                                <BiSolidPhoneCall className='contact-icon1' />
                                <p> {wordsData?.words?.phone}  </p>
                            </div>

                            <div className='d-flex my-4'>
                                <FaEnvelope className='contact-icon2' />
                                <p> {wordsData?.words?.email}  </p>
                            </div>

                            <div className='d-flex my-4'>
                                <IoLocation className='contact-icon3' />
                                <p> {wordsData?.words?.address}  </p>
                            </div>

                            <div className='d-flex my-4'>
                                <MdAccessTimeFilled className='contact-icon4' />
                                <p> {wordsData?.words?.Appointments}  </p>
                            </div>
                        </div>

                    </Col>

                    <Col md={6} className='d-flex flex-column'>
                        
                        <div className="mylocationmap" >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14490.166697167473!2d46.7077532!3d24.7768989!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efd0028bd0539%3A0xb4437134b3305ef!2z2LTYsdmD2Kkg2YXYrdmK2LUg2YTZhNmF2K3Yp9mF2KfYqSDZiNin2YTYp9iz2KrYtNin2LHYp9iqINin2YTZgtin2YbZiNmG2YrYqQ!5e0!3m2!1sen!2seg!4v1724568755361!5m2!1sen!2seg" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </Col>
                </Row>
            </ExampleComponent>

        </Container>
    )
}

export default Location
