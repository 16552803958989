import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Select from 'react-select';
import Loader from './Loader';
import ExampleComponent from '../Components/Example/Example';
import { Button } from "antd";
import { toast } from "react-toastify";

function ContactUs({language}) {

    const [contact, setContact] = useState({ name: "", phone: "", email: "", type: "", type_value: "", topic: "" });
    const [submitting, setSubmitting] = useState(false);

    const [wordsData, setWordsData] = useState([]);
    const [consData, setConsData] = useState([]);
    const [servData, setServData] = useState([]);
    const [loading, setLoading] = useState(false);
    
    
    useEffect(() => {
        (async () => {
          try {
            setLoading(true);
            const wordsResult = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/pages/contact_us`,
              headers: {
                Accept: "application/json",
                lang: language
              },
            });
            const consResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/consultaion-types`,
                headers: {
                  Accept: "application/json",
                  lang: language
                },
              });
              const servResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/services-types`,
                headers: {
                  Accept: "application/json",
                  lang: language
                },
              });
            setWordsData(wordsResult.data.data);
            setServData(servResult.data.data);
            setConsData(consResult.data.data);
            setLoading(false);
          } catch (err) {
          }
        })();
      }, [language]);

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
    };


    const successNotify = (msg) => {
        toast.success(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: "Tajawal" }}>{msg}</h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };
    
      const ErrorNotify = (msg) => {
        toast.error(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: "Tajawal" }}>{msg}</h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };


    const handleConfirm = () => {
        setSubmitting(true);
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/contact-us`, contact)
          .then((res) => {
            setSubmitting(false);
            successNotify(res.data.message);
            setContact({ name: "", phone: "", email: "", type: "", type_value: "", topic: "" });    
          })
          .catch((error) => {
            setSubmitting(false);
            ErrorNotify(error?.response?.data?.message);
          });
      };

    return (
        <>
         {loading ? <Loader/> :
        <div className='contact-us'>
            <div className='bg-head' style={{ backgroundImage: `url(${wordsData?.images?.main_image})`}}>
                <h5 className='bg-title'> {wordsData?.words?.contact_us} </h5>
            </div>
            <Container>
            <ExampleComponent>
                <Row className='align-items-center justify-content-center my-5'>
                    <Col md={12}>
                        <div className="contact-format">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-input d-flex flex-column">
                                        <input name="name" type="text" placeholder={wordsData?.words?.name} value={contact.name}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-input d-flex flex-column">
                                        <input name="phone" type="number" placeholder={wordsData?.words?.phone} value={contact.phone}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-input d-flex flex-column">
                                        <input name="email" type="email" placeholder={wordsData?.words?.email} value={contact.email}
                                            onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <select
                                        name="type"
                                        value={contact.type}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled>
                                        {wordsData?.words?.select}
                                        </option>
                                        <option value="consultation"> {wordsData?.words?.consultation_request} </option>
                                        <option value="service"> {wordsData?.words?.service_request} </option>
                                    </select>
                                </div>
{contact.type === "consultation" ?  <div className="col-lg-6">
                                    <select
                                        name="type_value"
                                        value={contact.type_value}
                                        onChange={handleChange}
                                    >
                                        {consData.map(item=>(
   <option value={item.id === consData[0].id ? "" : item.id} key={item.id}   disabled={item.id === consData[0].id}> {item.name} </option>
                                        ))}
                                     
                                    </select>
                                </div> : contact.type === "service" ?  <div className="col-lg-6">
                                    <select
                                        name="type_value"
                                        value={contact.type_value}
                                        onChange={handleChange}
                                    >
                                              {servData.map(item=>(
   <option value={item.id === servData[0].id ? "" : item.id} key={item.id}   disabled={item.id === servData[0].id}> {item.name} </option>
                                        ))}
                                    </select>
                                </div> : ""}
                               

                                <div className="col-lg-12">
                                    <div className="form-input d-flex flex-column">
                                        <textarea name="topic" id="" rows="4" placeholder={wordsData?.words?.message} value={contact.topic} onChange={handleChange}></textarea>
                                    </div>
                                </div>

                                <div className="col-lg-12 d-flex justify-content-end align-items-center mt-3">
                                    <Button
                htmlType="submit"
                loading={submitting}
                onClick={handleConfirm}
                className="send-mesg"
              >
                {!submitting && wordsData?.words?.send}
              </Button>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </ExampleComponent>
            </Container>
        </div>
}
</>
    )
}

export default ContactUs
