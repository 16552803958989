import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { Button } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import logo from "../../Assets/logo2.png";
import Loader from "../../Pages/Loader";

function Login({ setIsLoggedIn ,language }) {

  const [submitting, setSubmitting] = useState(false);
  const [wordsData, setWordsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({email: "",password: ""});

  const navigate = useNavigate();

  const location =useLocation();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/auth`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        setWordsData(wordsResult.data.data)
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language ,location]);

  const handleLoginChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const handleForgetPassword = () => {
    navigate("/forget-password")
  }

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Noto Naskh Arabic' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleLogin = async () => {
    setSubmitting(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, loginForm);
      setSubmitting(false);
      successNotify(res.data.message);
      setLoginForm({ email: "", password: "" });
      localStorage.setItem("mahace-user", res?.data?.data?.access_token);
      localStorage.setItem("mahace-userName", res?.data?.data?.user?.name);
      setIsLoggedIn(localStorage.getItem("mahace-user"));
      localStorage.getItem("mahace-type") === "service" ?  navigate("/service") : navigate("/consultation") ;
    } catch (error) {
      setSubmitting(false);
      ErrorNotify(error?.response?.data?.message);
    }
  };

  return (
    <>
                 {loading ? <Loader/> :
    <Container className="parent-login">
      <div className="login">
        <Row>
          <Col md={6} className="logo-back d-flex align-items-center justify-content-center">
            <img className='logo' src={logo} alt='' />
          </Col>
          <Col md={6}>
            <div className="login-sec">
              <h5> {wordsData?.words?.login_title} </h5>
              <div className="modal-inputs d-flex justify-content-center align-items-between flex-column">
              <div className="form-input form-input2">
              <span>
                  <MdEmail />
                </span>
              <input
                onChange={handleLoginChange}
                name="email"
                value={loginForm.email}
                type="email"
                placeholder={wordsData?.words?.email}
              />
              </div>
   <div className="form-input form-input2">
<span>
                  <RiLockPasswordFill />
                </span>
              <input
                onChange={handleLoginChange}
                name="password"
                value={loginForm.password}
                type="password"
                placeholder={wordsData?.words?.password}
              />
              </div>
              <Button
                htmlType="submit"
                loading={submitting}
                onClick={handleLogin}
              >
                {!submitting && wordsData?.words?.login_button}
              </Button>
              <h4 onClick={handleForgetPassword}>{language === "ar" ? "نسيت كلمة المرور؟" : "Forgot your password?"} </h4>
              <label className="mx-auto my-4">
                {wordsData?.words?.have_account}
                <Link to="/register" style={{ textDecoration: "none" }}>
                <span
                  style={{
                    cursor: "pointer",
                    padding: "0 10px",
                    fontWeight: "700",
                    color: "#B07C4B",
                  }}
                >
                  {wordsData?.words?.login_account}
                </span>
                </Link>
              </label>
            </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
}
    </>
  );
}

export default Login;
