import React, { useEffect, useState } from 'react'
import AboutSection from '../Components/AboutSection/AboutSection'
import Sliderr from './../Components/Slider/Slider';
import WhyUs from '../Components/WhyUs/WhyUs';
import ServicesSection from '../Components/ServicesSection/ServicesSection';
import RateSection from '../Components/RateSection/RateSection';
import axios from 'axios';
import Loader from './Loader';


function Home({ language }) {

    const [slidersData, setSlidersData] = useState([]);
    const [wordsData, setWordsData] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [mainServiceData, setMainServiceData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const slidersResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/services/sliders`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                const homeResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/pages/home`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                const commentResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/get-opinions`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                    const serviceResult = await axios({
                        method: "GET",
                        url: `${process.env.REACT_APP_BASE_URL}/services/services`,
                        headers: {
                            Accept: "application/json",
                            lang: language
                        },
                });
                const mainServiceResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/pages/services`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
            });
                setCommentData(commentResult.data.data);
                setSlidersData(slidersResult.data.data);
                setWordsData(homeResult.data.data);
                setServiceData(serviceResult.data.data);
                setMainServiceData(mainServiceResult.data.data);
                setLoading(false);
            } catch (err) {
            }
        })();
    }, [language]);

    return (
        <div className='home'>
            {loading ? <Loader/> : <> 
                <Sliderr slidersData={slidersData} />
            <AboutSection wordsData={wordsData} />
            <WhyUs wordsData={wordsData} />
            <ServicesSection wordsData={wordsData} serviceData={serviceData} mainServiceData={mainServiceData}/>
            <RateSection wordsData={wordsData} language={language} commentData={commentData} />
            </> }
          
        </div>
    )
}

export default Home
