import React, { useEffect, useRef, useState } from 'react';
import "./Slider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Sliderr({slidersData}) {

    const [currentIndex, setCurrentIndex] = useState(0);

    // Set up transition functions
    const doTransition = () => {
        const slideContainer = document.querySelector(".slick-list");
        if (slideContainer) {
            slideContainer.classList.add("u-scale-out");
            const currentSlide = document.querySelector(".slick-list");
            if (currentSlide) {
                currentSlide.classList.remove("animateIn");
            }
        }
        exitTransition();
    };

    const exitTransition = () => {
        setTimeout(() => {
            const slideContainer = document.querySelector(".slick-list");
            if (slideContainer) {
                slideContainer.classList.remove("u-scale-out");
                const currentSlide = document.querySelector(".slick-list");
                if (currentSlide) {
                    currentSlide.classList.add("animateIn");
                }
            }
        }, 2000);
    };

    useEffect(() => {
        // Handle initial animation
        const currentSlide = document.querySelector(".slick-slide[data-index='0']");
        if (currentSlide) {
            currentSlide.classList.add("initialAnimation");
        }

        doTransition();

    }, [currentIndex]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1200,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        draggable: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        beforeChange: (current, next) => {
            if (currentIndex === 0) {
                doTransition();
            } else {
              doTransition();
            }
            setCurrentIndex(next);
        }
    };

    return (
        <div className='slider-parent'>
             <section className='c-slider'>
      <Slider className="c-slider-init" {...settings}>
  {slidersData.map(item=>(
    <div key={item.id}>

      <div className='c-slide c-slide3' style={{ backgroundImage: `url(${item.image})`}}>
      <div className='c-slide-content'>
          <div className='c-wrap c-wrap--line'>
            <h2 className='c-slide__title'>
            {item.title}
              <span className='c-slide__title--large'> <span style={{color:"#B07C4B"}}> {item.description} </span> </span>
            </h2>
          </div>
          <div className='c-wrap c-wrap--small'>
            <div className='c-slide__info'>
            <Link to={item.url}>
              <h3 className='c-slide__subtitle'> {item.button} </h3>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-dRK"></div>
      </div>
      </div>

  ))}

     

          
          </Slider>
      </section>
        </div>
    );
}

export default Sliderr;
