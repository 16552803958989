import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Accordion from "react-bootstrap/Accordion";
import ExampleComponent from '../Components/Example/Example';
import axios from 'axios';
import Loader from './Loader';

function Questions({language}) {

    const [faqsData, setFaqsData] = useState([]);
    const [wordsData, setWordsData] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        (async () => {
          try {
            setLoading(true);
            const faqsResult = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/faqs`,
              headers: {
                Accept: "application/json",
                lang: language
              },
            });
            const wordsResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/pages/faqs`,
                headers: {
                  Accept: "application/json",
                  lang: language
                },
              });
            setFaqsData(faqsResult.data.data);
            setWordsData(wordsResult.data.data)
            setLoading(false);
          } catch (err) {
          }
        })();
      }, [language]);


    return (
        <>
         {loading ? <Loader/> :
        <div className='most-questions'>
            <div className='bg-head' style={{ backgroundImage: `url(${wordsData?.images?.main_image})`}}>
                <h5 className='bg-title'> {wordsData?.words?.faqs} </h5>
            </div>
            <Container>
                <div className='my-5'>
                    {faqsData?.map(item => (
                        <ExampleComponent key={item.id}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>{item.question}</Accordion.Header>
                                    <Accordion.Body>
                                        {item.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            </ExampleComponent>
      ))}

                            </div>
                        </Container>
    </div>
}
</>
                )
}

                export default Questions
