import React from 'react'
import "./AboutSection.css"
import { Col, Container, Row } from 'react-bootstrap'
import img from "../../Assets/Heroo.png";
import underLineImg from "../../Assets/title line.png"
import { BsArrowLeft } from "react-icons/bs";
import ExampleComponent from '../Example/Example';
import { Link } from 'react-router-dom';


function AboutSection({wordsData}) {
  return (
    <div className='about-section'>
      <Container>
        <Row className='align-items-center'>
          <Col md={6}>
          <ExampleComponent>
          <p> {wordsData?.words?.about_us} </p>

          <Link to="/contact-us"> <button> {wordsData?.words?.contact_button} <BsArrowLeft className='me-2 arrow'/> </button> </Link> 
            </ExampleComponent>
          </Col>

          <Col md={6}>
          <ExampleComponent>
            <img className='col-img' src={wordsData?.images?.about_image} alt='' />
            </ExampleComponent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AboutSection
