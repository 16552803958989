import React from 'react'
import "./ServicesSection.css"
import { Container } from 'react-bootstrap';

import { BsArrowLeft } from "react-icons/bs";
import ExampleComponent from '../Example/Example';
import { Link } from 'react-router-dom';


function ServicesSection({wordsData ,serviceData ,mainServiceData}) {
    return (
        <div className="services-section">
            <Container>
            <ExampleComponent>

                <div className='d-flex align-items-center justify-content-center'>
                    <h4> {wordsData?.words?.our_services} <br />  <img src={wordsData?.images?.under_titl} alt='' /> </h4>
                </div>
                </ExampleComponent>
                <ExampleComponent>
                <div className="row my-5">

{serviceData.map(item=>(
     <div className="col-lg-4" key={item.id}>
     <div className='card'>
     <img src={item.image} alt="" style={{width:"100%",height:"100%"}}/>
     <div className='service-content'>
         <div className='sec-first'>  </div>
         <div className='sec-second'>
         <h6> {item.title} </h6>
         <p> {item.description} </p>
        <Link to={`/service-details/${item.id}`}> <button> {mainServiceData?.words?.more_details} <BsArrowLeft className='me-2 arrow'/> </button> </Link>
         </div>
         
     </div>
     <div className='vert-sec'> <h5> {item.label} </h5> </div>
     </div>
 </div>
))}
                            </div>
               
                </ExampleComponent>

            </Container>
        </div>
    )
}

export default ServicesSection
