import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { Button } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import logo from "../../Assets/logo2.png";
import { FaUser } from "react-icons/fa";
import Loader from "../../Pages/Loader";

function Register({ setIsLoggedIn ,language }) {

  const [submitting, setSubmitting] = useState(false);
  const [wordsData, setWordsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [registerForm, setRegisterForm] = useState({name: "",email: "",password: ""});

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/auth`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        setWordsData(wordsResult.data.data)
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);

  const handleRegisterChange = (e) => {
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Noto Naskh Arabic' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleRegister = async () => {
    setSubmitting(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/sign-up`, registerForm);
      setSubmitting(false);
      successNotify(res.data.success);
      setRegisterForm({
        name: "",
        email: "",
        password: "",
      });
      localStorage.setItem("mahace-user", res?.data?.data?.access_token);
      localStorage.setItem("mahace-userName", res?.data?.data?.client?.name);
      setIsLoggedIn(localStorage.getItem("mahace-user"));
      localStorage.getItem("mahace-type") === "service" ?  navigate("/service") : navigate("/consultation") ;
    } catch (error) {
      setSubmitting(false);
      ErrorNotify(error?.response?.data?.message);
    }
  };
  


  return (
    <>
             {loading ? <Loader/> :
    <Container className="parent-login">
      <div className="login">
        <Row>

          <Col md={6} className="logo-back d-flex align-items-center justify-content-center">
            <img className='logo' src={logo} alt='' />
          </Col>

          <Col md={6}>
            <div className="login-sec">
              <h5>  {wordsData?.words?.login_account}  </h5>

              <div className="register-inputs">
              <Row>
                <Col xs={12}>
                <div className="form-input form-input2">
                <span>
                  <FaUser />
                </span>
                                    <input
                      name="name"
                      value={registerForm.name}
                      onChange={handleRegisterChange}
                      type="text"
                      placeholder={wordsData?.words?.name}
                    />
                  </div>
                </Col>
                <Col xs={12} md={12}>
                <div className="form-input form-input2">
                <span>
                  <MdEmail />
                </span>

                  <input
                    name="email"
                    value={registerForm.email}
                    onChange={handleRegisterChange}
                    type="email"
                    placeholder={wordsData?.words?.email}
                  />
                  </div>
                </Col>
                <Col xs={12}>
                
              <div className="form-input form-input2">
                <span>
                  <RiLockPasswordFill />
                </span>
                  <input
                    name="password"
                    value={registerForm.password}
                    onChange={handleRegisterChange}
                    type="password"
                    placeholder={wordsData?.words?.password}
                  />
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  htmlType="submit"
                  loading={submitting}
                  onClick={handleRegister}
                >
                  {!submitting && wordsData?.words?.create_account_button}
                </Button>
              </div>
              <label className="mx-auto my-4 d-flex justify-content-center align-items-center">
                {wordsData?.words?.already_have_account}
                <Link to="/login" style={{ textDecoration: "none" }}>
                <span
                  style={{
                    cursor: "pointer",
                    padding: "0 10px",
                    fontWeight: "700",
                    color: "#B07C4B ",
                  }}
                >
                  {wordsData?.words?.login_title}
                </span>
                </Link>
              </label>
            </div>
            </div>
          </Col>
        </Row>

      </div>
    </Container>
}
    </>
  );
}

export default Register;
