import React, { useEffect, useState } from 'react'
import { Card, Col, Container ,Row } from 'react-bootstrap'

import ExampleComponent from '../Components/Example/Example'
import axios from 'axios'
import Loader from './Loader'

function AboutUs({language}) {        

const [wordsData, setWordsData] = useState([]);
const [teamData, setTeamData] = useState([]);
const [loading, setLoading] = useState(false);


useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const wordsResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/pages/about_us`,
          headers: {
            Accept: "application/json",
            lang: language
          },
        });
        const teamResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/services/employees`,
                headers: {
                  Accept: "application/json",
                  lang: language
                },
              });
        setWordsData(wordsResult.data.data);
        setTeamData(teamResult.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [language]);


  return (
        <>
         {loading ? <Loader/> :
    <div className='about-us'>
 <div className='bg-head' style={{ backgroundImage: `url(${wordsData?.images?.main_image})`}}>
<h5 className='bg-title'> {wordsData?.words?.about_us_title} </h5>
      </div>
      <Container>
        <Row className='my-5'>
            <Col md={12}>
            <ExampleComponent>
            <div className="d-flex justify-content-start mb-4"><svg className='text-icon' xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none"><path d="M29.754 27.2874C31.2957 25.5916 32.2207 23.7416 32.2207 20.6583C32.2207 15.2624 28.3665 10.4833 22.9707 8.0166L21.5832 10.0208C26.6707 12.7958 27.7499 16.3416 28.0582 18.6541C27.2874 18.1916 26.2082 18.0374 25.129 18.1916C22.354 18.4999 20.1957 20.6583 20.1957 23.5874C20.1957 24.9749 20.8124 26.3624 21.7374 27.4416C22.8165 28.5208 24.0499 28.9833 25.5915 28.9833C27.2874 28.9833 28.829 28.2124 29.754 27.2874ZM14.3374 27.2874C15.879 25.5916 16.804 23.7416 16.804 20.6583C16.804 15.2624 12.9499 10.4833 7.55404 8.0166L6.16654 10.0208C11.254 12.7958 12.3332 16.3416 12.6415 18.6541C11.8707 18.1916 10.7915 18.0374 9.71237 18.1916C6.93737 18.4999 4.77904 20.6583 4.77904 23.5874C4.77904 24.9749 5.3957 26.3624 6.3207 27.4416C7.2457 28.5208 8.6332 28.9833 10.1749 28.9833C11.8707 28.9833 13.4124 28.2124 14.3374 27.2874Z" fill="#B07C4B"></path></svg></div>
            <p className='start-text'> {wordsData?.words?.about_us} </p>
            <div className="d-flex justify-content-end mt-4"><svg className='text-icon' xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"><path d="M7.57995 27.7171C6.03828 26.0213 5.11328 24.1713 5.11328 21.088C5.11328 15.6921 8.96745 10.913 14.3633 8.44629L15.7508 10.4505C10.6633 13.2255 9.58412 16.7713 9.27578 19.0838C10.0466 18.6213 11.1258 18.4671 12.2049 18.6213C14.9799 18.9296 17.1383 21.088 17.1383 24.0171C17.1383 25.4046 16.5216 26.7921 15.5966 27.8713C14.5174 28.9505 13.2841 29.413 11.7424 29.413C10.0466 29.413 8.50495 28.6421 7.57995 27.7171ZM22.9966 27.7171C21.4549 26.0213 20.5299 24.1713 20.5299 21.088C20.5299 15.6921 24.3841 10.913 29.7799 8.44629L31.1674 10.4505C26.0799 13.2255 25.0008 16.7713 24.6924 19.0838C25.4633 18.6213 26.5424 18.4671 27.6216 18.6213C30.3966 18.9296 32.5549 21.088 32.5549 24.0171C32.5549 25.4046 31.9383 26.7921 31.0133 27.8713C30.0883 28.9505 28.7008 29.413 27.1591 29.413C25.4633 29.413 23.9216 28.6421 22.9966 27.7171Z" fill="#B07C4B"></path></svg></div>
            </ExampleComponent>
            </Col>
        </Row>
        {window.innerWidth <= 768 ?
    <Row className='align-items-center gx-5'>
 
        <Col md={7}  className='my-5'>
        <ExampleComponent>
        <h6> {wordsData?.words?.message} </h6>
        <p> {wordsData?.words?.message_desc} </p>
        </ExampleComponent>
        </Col>

        <Col md={5} className='my-5'>
        <ExampleComponent>

<img className="w-100" src={wordsData?.images?.message} alt=''/>
</ExampleComponent>
        </Col>

        <Col md={7}  className='my-5'>
        <ExampleComponent>

        <h6> {wordsData?.words?.Vision} </h6>
        <p> {wordsData?.words?.Vision_desc} </p>
        </ExampleComponent>
        </Col>

        <Col md={5}  className='my-5'>
        <ExampleComponent>

<img className="w-100" src={wordsData?.images?.Vision} alt=''/>
</ExampleComponent>
        </Col>

        <Col md={7}  className='my-5'>
        <ExampleComponent>

        <h6> {wordsData?.words?.goal} </h6>
       <p> {wordsData?.words?.goal_desc} </p>
       </ExampleComponent>
        </Col>

        <Col md={5} className='my-5'>
        <ExampleComponent>

<img className="w-100" src={wordsData?.images?.goal} alt=''/>
</ExampleComponent>
        </Col>


    </Row> :  <Row className='align-items-center gx-5'>
 
 <Col md={7}  className='my-5'>
 <ExampleComponent>
 <h6> {wordsData?.words?.message} </h6>
 <p> {wordsData?.words?.message_desc} </p>
 </ExampleComponent>
 </Col>

 <Col md={5} className='my-5'>
 <ExampleComponent>

<img className="w-100" src={wordsData?.images?.message} alt=''/>
</ExampleComponent>
 </Col>

 <Col md={5}  className='my-5'>
 <ExampleComponent>

<img className="w-100" src={wordsData?.images?.Vision} alt=''/>
</ExampleComponent>
 </Col>

 <Col md={7}  className='my-5'>
 <ExampleComponent>

 <h6> {wordsData?.words?.Vision} </h6>
 <p> {wordsData?.words?.Vision_desc} </p>
 </ExampleComponent>
 </Col>

 <Col md={7}  className='my-5'>
 <ExampleComponent>

 <h6> {wordsData?.words?.goal} </h6>
<p> {wordsData?.words?.goal_desc} </p>
</ExampleComponent>
 </Col>

 <Col md={5} className='my-5'>
 <ExampleComponent>

<img className="w-100" src={wordsData?.images?.goal} alt=''/>
</ExampleComponent>
 </Col>


</Row>
}
    <div className='story-section3 mt-5'>
    <ExampleComponent>
                  <div className="section3">
                <div className="header3" style={{ backgroundImage : `url(${wordsData?.images?.our_teams})`}}>
                    <div className="transperant-bg3">
                        <div className='content-ship container'>
                        <h5> {wordsData?.words?.Our_team} </h5>
                        <p> {wordsData?.words?.Our_team_desc} </p>
                        </div>
                    </div>
                </div>
            </div>
            </ExampleComponent>
           
            <div className='team-info mt-4'>
                <Container>
                    <Row>
                        {teamData?.map(item=>(
                                <Col xs={6} md={3} key={item.id}>
                                        <ExampleComponent>
                                <Card>
                                        <Card.Img variant="top" src={item.image} />
                                        <Card.Body>
                                            <Card.Title> {item.title} </Card.Title>
                                            <div className="head-title">
                                                <p> {item.description} </p>
                                            </div>
                                        </Card.Body>
                                </Card>
                                </ExampleComponent>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
    </div>

    </Container>
    </div>
}
</>
  )
}

export default AboutUs
