import React from 'react';
import { useInView } from 'react-intersection-observer';

const ExampleComponent = ({ children }) => {
    const { ref, inView } = useInView({
        triggerOnce: false, // Animations will trigger only once
        threshold: 0.1, // Adjust as needed (0.1 means 10% of the component should be visible to trigger)
    });

    return (
        <div
            ref={ref}
            className={`animate-section ${inView ? 'animate' : ''}`}
        >
            {children}
        </div>
    );
};

export default ExampleComponent;
