import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import NavBarr from './Components/NavBar/NavBarr';
import Home from './Pages/Home';
import Footer from './Components/Footer/Footer';
import ReactWhatsapp from "react-whatsapp";
import { useEffect, useState } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import logImg from "./Assets/logo.png";
import { BackTop } from "antd";
import { AiOutlineToTop } from "react-icons/ai";
import Services from './Pages/Services';
import AboutUs from './Pages/AboutUs';
import Questions from './Pages/Questions';
import ContactUs from './Pages/ContactUs';
import TermConditions from './Pages/TermConditions';
import Privacy from './Pages/Privacy';
import Location from './Components/Location/Location';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Consultation from './Pages/Consultation';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ServicesDetails from './Pages/ServicesDetails';
import Register from './Components/Register/Register';
import Login from './Components/Login/Login';
import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import NewPassword from './Components/NewPassword/NewPassword';
import Service from './Pages/Service';
import Blogs from './Pages/Blogs';
import BlogDetails from './Pages/BlogDetails';

const Layout = ({ children, setIsLoggedIn, isLoggedIn, setLanguage, language, socialData }) => {
  const location = useLocation();
  const hideNavAndFooterRoutes = ['/login', '/register', '/forget-password', '/forget-password-code', '/new-passowrd'];
  const shouldHideNavAndFooter = hideNavAndFooterRoutes.includes(location.pathname);

  return (
    <>
      {!shouldHideNavAndFooter && (
        <>
          <NavBarr setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} setLanguage={setLanguage} language={language} socialData={socialData} />
        </>
      )}
      {children}
      {!shouldHideNavAndFooter && (
        <>
          <Location language={language} />
          <Footer language={language} socialData={socialData} />
        </>
      )}
    </>
  );
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("mahace-user"));
  const [language, setLanguage] = useState(localStorage.getItem("mahace-lang") ? localStorage.getItem("mahace-lang") : 'ar');
  const [whatsShow, setWhatsShow] = useState(false);
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [hideWhats, setHideWhats] = useState(true)
  const [showBtn, setShowBtn] = useState(false);
  const [socialData, setSocialData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const socialResult = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/settings`,
          headers: {
            Accept: "application/json",
          },
        });
        setSocialData(socialResult.data.data);
        setNumber(socialResult?.data?.data?.whatsup)
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const style2 = {
    height: 60,
    width: 60,
    lineHeight: "50px",
    borderRadius: 50,
    backgroundColor: "#25d366",
    color: "white",
    textAlign: "center",
    fontSize: 38,
    border: "1px solid #25d366",
    boxShadow: "rgb(0 0 0 / 15%) 0px 4px 12px 0px",
  };

  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: 2,
    backgroundColor: "#1b1a1d",
    color: "white",
    textAlign: "center",
    fontSize: 30,
    border: "1px solid white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const handleBckToTop = () => {
    if (window.scrollY >= 250) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  };

  useEffect(() => {
    handleBckToTop();
    window.addEventListener("scroll", handleBckToTop);
  }, []);

  return (
    <>
      {socialData && <Helmet>
        <link rel="icon" href={socialData.favicon} />
      </Helmet>}

      <div className={language === "en" ? "App ltr" : "App rtl"} style={{ direction: language === "en" ? "ltr" : "rtl" }}>
        <BrowserRouter>
          <Layout setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} setLanguage={setLanguage} language={language} socialData={socialData}>
            <Routes>
              <Route path="/" element={<Home language={language} />} />
              <Route path="/consultation" element={isLoggedIn ? <Consultation language={language} /> : <Navigate to="/" />} />
              <Route path="/service" element={isLoggedIn ? <Service language={language} /> : <Navigate to="/" />} />
              <Route path="/services" element={<Services language={language} />} />
              <Route path="/blogs" element={<Blogs language={language} />} />
              <Route path="/blog-details/:id" element={<BlogDetails language={language} />} />
              <Route path="/about-us" element={<AboutUs language={language} />} />
              <Route path="/most-questions" element={<Questions language={language} />} />
              <Route path="/contact-us" element={<ContactUs language={language} />} />
              <Route path="/privacy" element={<Privacy language={language} />} />
              <Route path="/term-conditions" element={<TermConditions language={language} />} />
              <Route path="/service-details/:id" element={<ServicesDetails language={language} />} />
              <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} language={language} />} />
              <Route path="/register" element={<Register setIsLoggedIn={setIsLoggedIn} language={language} />} />
              <Route path="/forget-password" element={<ForgetPassword language={language} />} />
              <Route path="/new-passowrd" element={<NewPassword setIsLoggedIn={setIsLoggedIn} language={language} />} />
            </Routes>
          </Layout>
        </BrowserRouter>
        <ToastContainer style={{ marginTop: "42px", textAlign: "center" }} />

        <BackTop className={showBtn ? "back-top-icon show" : "back-top-icon hide"}>
          <div style={style}>
            <AiOutlineToTop />
          </div>
        </BackTop>

        {hideWhats ? <>
          <button onClick={() => setWhatsShow(!whatsShow)} className="whatsapp-icon show">
            <div style={style2}>
              <FaWhatsapp />
            </div>
          </button>

          <div className={whatsShow ? "content" : "hide"}>
            <div className="d-flex align-items-center justify-content-around head-whats">
              <h5> محيص </h5>
              <img src={logImg} alt="" />
            </div>

            <div className="whats-half">
              <p>أهلاً بك! 🤝</p>
              <p> كيف يمكن أن نساعدك؟</p>
            </div>

            <div className="library d-flex justify-content-around align-items-center">
              <ReactWhatsapp number={number} message={message}>
                <MdSend className="send-ico" />
              </ReactWhatsapp>
              <input
                id="message"
                placeholder="اكتب رسالتك..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </> : null}
      </div>
    </>
  );
}

export default App;
