import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import footerLogo from "../../Assets/logo2.png"
import "./Footer.css"
import { AiFillYoutube, AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaXTwitter } from "react-icons/fa6";


function Footer({language ,socialData}) {

    const [wordsData, setWordsData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const wordsResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/pages/footer`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                setWordsData(wordsResult.data.data);
                setLoading(false);
            } catch (err) {
            }
        })();
    }, [language]);

    return (
        <div className='footer'>
            <Container>
                <div className='footer-content d-flex align-items-center justify-content-between'>
                    <div>
                        <img src={socialData?.second_logo} alt='' />
                    </div>
                    <div>
                        <div className='d-flex align-items-center social'>
                            <Link to={socialData?.instagram}>
                                <AiFillInstagram className='media-icons' />
                            </Link>
                            <Link to={socialData?.twitter}>
                                <FaXTwitter className='media-icons' />
                            </Link>
                            <Link to={socialData?.facebook}>
                                <FaFacebookF className='media-icons' />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between all-rights mt-4'>
                    <div className='d-flex align-items-center'>
                        <p> {wordsData?.words?.all_rights_are_saved} </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <Link to="/privacy" className="ms-5">
                            <p> {wordsData?.words?.privacy} </p>
                        </Link>

                        <Link to="/term-conditions">
                            <p className="" > {wordsData?.words?.terms} </p>
                        </Link>
                    </div>

                </div>
            </Container>
        </div>
    )
}

export default Footer
