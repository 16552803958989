import React, { useState } from 'react'
import "./WhyUs.css"
import { Container, Row, Col, Card } from 'react-bootstrap'
import cardImg1 from "../../Assets/Mask group.png"
import cardImg2 from "../../Assets/Mask group2.png"
import cardImg3 from "../../Assets/Mask group3.png"
import ExampleComponent from '../Example/Example'



function WhyUs({wordsData}) {

    return (
        <div className='why-us'>
            <Container>
                <ExampleComponent>

                    <div className='d-flex align-items-center justify-content-center'>
                        <h6> {wordsData?.words?.why_us} <br />  <img src={wordsData?.images?.under_titl} alt='' /> </h6>
                    </div>
                </ExampleComponent>
                <ExampleComponent>

                    <Row className='my-4 equal-height'>
                        <Col md={4}>
                            <Card>
                                <div className="border-one"></div>
                                <div className="border-two"></div>
                                <Card.Img variant="top" src={cardImg1} />
                                <Card.Body>
                                    <Card.Title> {wordsData?.words?.message} </Card.Title>
                                    <Card.Text>
                                     {wordsData?.words?.message_desc}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card>
                                <div className="border-one"></div>
                                <div className="border-two"></div>
                                <Card.Img variant="top" src={cardImg2} />
                                <Card.Body>
                                    <Card.Title> {wordsData?.words?.Vision} </Card.Title>
                                    <Card.Text>
                                    {wordsData?.words?.Vision_desc}
                                                                            </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card >
                                <div className="border-one"></div>
                                <div className="border-two"></div>
                                <Card.Img variant="top" src={cardImg3} />
                                <Card.Body>
                                    <Card.Title> {wordsData?.words?.goal} </Card.Title>
                                    <Card.Text>
                                    {wordsData?.words?.goal_desc}
                                                                             </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </ExampleComponent>

            </Container>
        </div>
    )
}

export default WhyUs
