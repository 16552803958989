import React from 'react'
import img from "../Assets/logo.png"

function Loader() {
    return (
<div className='loader-parent'>

  <img src={img} alt=''/>

<div className="spinner-grow mt-3" role="status">
</div>
        </div>
    )
}

export default Loader
