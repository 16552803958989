import React, { useEffect, useState } from 'react'
import { Col, Container ,Row } from 'react-bootstrap'
import { BsArrowLeft } from "react-icons/bs";
import ExampleComponent from '../Components/Example/Example'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Parse from "html-react-parser";
import Loader from './Loader'


function Services({language}) {

  const [wordsData, setWordsData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
        try {
            setLoading(true);
            const wordsResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/services/services`,
                headers: {
                    Accept: "application/json",
                    lang: language
                },
            });
            const mainResult = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/pages/services`,
              headers: {
                Accept: "application/json",
                lang: language
              },
            });
          setMainData(mainResult.data.data);
            setWordsData(wordsResult.data.data);
            setLoading(false);
        } catch (err) {
        }
    })();
}, [language]);


  return (
    <>
    {loading ? <Loader/> :
    <div className='services'>
      <div className='bg-head' style={{ backgroundImage: `url(${mainData?.images?.main_image})`}}>
<h5 className='bg-title'> {mainData?.words?.services} </h5>
      </div>
      <Container>
      {wordsData.map((item, index) => (
    <Row className='align-items-center gx-5' key={item.id}>
        {index % 2 === 0 || window.innerWidth <= 768 ? (
            <>
                <Col md={4} className='my-5'>
                    <ExampleComponent>
                        <img className="w-100" src={item.image} alt=''/>
                    </ExampleComponent>
                </Col>
                <Col md={8} className='my-5'>
                    <ExampleComponent>
                        <h6>{item.title}</h6>
                        {Parse(`${item.description2}`)}
                        <Link to="/contact-us">
                            <button>
                                {language === "ar" ? "تواصل معنا" : "Contact Us"} 
                                <BsArrowLeft className='me-2 arrow'/>
                            </button>
                        </Link>
                    </ExampleComponent>
                </Col>
            </>
        ) : (
            <>
                <Col md={8} className='my-5'>
                    <ExampleComponent>
                        <h6>{item.title}</h6>
                        {Parse(`${item.description2}`)}
                        <Link to="/contact-us">
                            <button>
                                {language === "ar" ? "تواصل معنا" : "Contact Us"} 
                                <BsArrowLeft className='me-2 arrow'/>
                            </button>
                        </Link>
                    </ExampleComponent>
                </Col>
                <Col md={4} className='my-5'>
                    <ExampleComponent>
                        <img className="w-100" src={item.image} alt=''/>
                    </ExampleComponent>
                </Col>
            </>
        )}
    </Row>
))}

    </Container>
    </div>}
    </>
  )
}

export default Services
