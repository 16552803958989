import React, { useState } from 'react'
import "./RateSection.css"
import { Container, Row, Col, Card } from 'react-bootstrap'
import { BsArrowLeft } from "react-icons/bs";
import BasicModal from '../BasicModal/BasicModal';
import ExampleComponent from '../Example/Example';


function RateSection({wordsData ,language ,commentData}) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
      };
      
      const commentChunks = chunkArray(commentData, 3);

    return (
        <div className='rate-section'>
            <Container>
                <ExampleComponent>

                    <div className='d-flex align-items-center justify-content-center'>
                        <h6> {wordsData?.words?.customers_opinions} <br />  <img src={wordsData?.images?.under_titl} alt='' /> </h6>
                    </div>
                </ExampleComponent>
                <ExampleComponent>

                <div id="carouselExampleIndicators" className="carousel slide mt-4" data-bs-ride="carousel" data-bs-interval="5000">
  <div className="carousel-inner">
    {commentChunks.map((chunk, chunkIndex) => (
      <div className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`} key={chunkIndex}>
        <div className="row">
          {chunk.map(item => (
            <div className="col-lg-4" key={item.id}>
              <div className="user-rate">
                <div className="border-one"></div>
                <div className="border-two"></div>
                <div>
                  <div className="d-flex align-items-center my-2">
                    <div className="user-name">
                      <h6>{item.name}</h6>
                    </div>
                  </div>
                  <p className="m-0">{item.opinion}</p>
                  <div className="d-flex align-items-center justify-content-end mt-2">
                    <svg className='text-icon' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                      <path d="M9.64174 5.62101C10.2419 5.36931 10.4936 4.65296 10.2226 4.07213L9.27389 2.07796C9.00283 1.51668 8.34456 1.2648 7.76373 1.51668C6.11805 2.21368 4.70471 3.12364 3.58177 4.22721C2.20715 5.5242 1.27782 7.01499 0.774437 8.66067C0.271053 10.3259 0 12.5909 0 15.4757V21.4195C0 22.0584 0.522745 22.5812 1.16166 22.5812H8.7705C9.40941 22.5812 9.93216 22.0584 9.93216 21.4195V13.8107C9.93216 13.1716 9.40941 12.649 8.7705 12.649H5.13065C5.16937 10.6934 5.63403 9.12533 6.48591 7.94412C7.1829 6.99583 8.22839 6.22139 9.64174 5.62101ZM23.3106 5.62101C23.9107 5.36931 24.1622 4.65296 23.8914 4.07213L22.9427 2.09751C22.6716 1.53604 22.0134 1.28435 21.4325 1.53604C19.8062 2.23304 18.4121 3.143 17.2699 4.22721C15.8951 5.54376 14.9466 7.03455 14.4431 8.68023C13.9397 10.3064 13.6882 12.5718 13.6882 15.4759V21.4197C13.6882 22.0586 14.2109 22.5814 14.8498 22.5814H22.4587C23.0976 22.5814 23.6203 22.0586 23.6203 21.4197V13.8109C23.6203 13.1718 23.0976 12.6492 22.4587 12.6492H18.7995C18.8382 10.6936 19.303 9.12553 20.1547 7.94432C20.8517 6.99583 21.8972 6.22139 23.3106 5.62101Z" fill="#B07C4B"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
  <div className="carousel-indicators mt-4">
    {commentChunks.map((_, index) => (
      <button
        key={index}
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to={index}
        className={index === 0 ? "active" : ""}
        aria-current={index === 0 ? "true" : ""}
        aria-label={`Slide ${index + 1}`}
      ></button>
    ))}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

                    
                     </ExampleComponent>
                <ExampleComponent>

                    <div className='d-flex align-items-center justify-content-center'>
                        <button className='add-comment' onClick={handleShow}> {wordsData?.words?.Leave_comment}  <BsArrowLeft className='me-2 arrow' /> </button>
                    </div>
                </ExampleComponent>

                <BasicModal show={show} handleClose={handleClose} wordsData={wordsData} language={language} />
            </Container>
        </div>
    )
}

export default RateSection
