import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import ExampleComponent from '../Components/Example/Example';
import underLineImg from "../Assets/title line.png"
import { DatePicker, TimePicker } from 'antd';
import axios from 'axios';
import { Button } from "antd";
import { toast } from "react-toastify";
import Loader from './Loader';


function Consultation({ language }) {

    const [contact, setContact] = useState({ consult_type_id: "", topic: "" ,date : "" ,time : "" });
    const [submitting, setSubmitting] = useState(false);

    const [wordsData, setWordsData] = useState([]);
    const [consData, setConsData] = useState([]);
    const [readCons, setReadCons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);


    const token = localStorage.getItem("mahace-user");


    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const wordsResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/pages/consultation`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                const consResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/consultaion-types`,
                    headers: {
                        Accept: "application/json",
                        lang: language
                    },
                });
                const readResult = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/get-consultaions`,
                    headers: {
                        Accept: "application/json",
                        lang: language,
                        Authorization: "Bearer " + token,
                    },
                });
                setWordsData(wordsResult.data.data);
                setConsData(consResult.data.data);
                setReadCons(readResult.data.data)
                setLoading(false);
            } catch (err) {
            }
        })();
    }, [language]);


    useEffect(() => {
      (async () => {
          try {
              const readResult = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/get-consultaions`,
                  headers: {
                      Accept: "application/json",
                      lang: language,
                      Authorization: "Bearer " + token,
                  },
              });
              setReadCons(readResult.data.data)
          } catch (err) {
          }
      })();
  }, [language ,refresh]);


    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
    };

    const onChange = (date, dateString) => {
        setContact(prevContact => ({ ...prevContact, date: dateString }));
    };

    const onChange2 = (time, timeString) => {
        setContact(prevContact => ({ ...prevContact, time: timeString }));
    };

    const successNotify = (msg) => {
        toast.success(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: "Tajawal" }}>{msg}</h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };
    
      const ErrorNotify = (msg) => {
        toast.error(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: "Tajawal" }}>{msg}</h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };

      const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      }

    const handleConfirm = () => {
        setSubmitting(true);
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/send-consultaion`, contact ,{headers})
          .then((res) => {
            setSubmitting(false);
            successNotify(res.data.message);
            setContact({ consult_type_id: "", topic: "" ,date : "" ,time : "" });   
            setRefresh(true);
          })
          .catch((error) => {
            setSubmitting(false);
            ErrorNotify(error?.response?.data?.message);
          });
      };

    return (
        <>
         {loading ? <Loader/> :
        <div className='consultation'>
            <div className='bg-head' style={{ backgroundImage: `url(${wordsData?.images?.main_image})` }}>
                <h5 className='bg-title'> {wordsData?.words?.consultation} </h5>
            </div>
            <Container>

                <div className='row my-5'>
                    <div className='col-lg-5'>
                        <ExampleComponent>
                            {readCons.map(item=>(
    <div className="user-cons mb-3" key={item.id}>
    <div className="border-one"></div>
    <div className="border-two"></div>
    <div>
      <div className="d-flex align-items-center my-2">
        <div className="user-name">
          <h6>{item.topic}</h6>
        </div>
      </div>
      <p className="m-0">{item.response === null ? language === "ar" ? "سيتم الرد في اقرب وقت" : "We will reply as soon as possible"  : item.response}</p>
      <div className="d-flex align-items-center justify-content-end mt-2">
        <svg className='text-icon' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
          <path d="M9.64174 5.62101C10.2419 5.36931 10.4936 4.65296 10.2226 4.07213L9.27389 2.07796C9.00283 1.51668 8.34456 1.2648 7.76373 1.51668C6.11805 2.21368 4.70471 3.12364 3.58177 4.22721C2.20715 5.5242 1.27782 7.01499 0.774437 8.66067C0.271053 10.3259 0 12.5909 0 15.4757V21.4195C0 22.0584 0.522745 22.5812 1.16166 22.5812H8.7705C9.40941 22.5812 9.93216 22.0584 9.93216 21.4195V13.8107C9.93216 13.1716 9.40941 12.649 8.7705 12.649H5.13065C5.16937 10.6934 5.63403 9.12533 6.48591 7.94412C7.1829 6.99583 8.22839 6.22139 9.64174 5.62101ZM23.3106 5.62101C23.9107 5.36931 24.1622 4.65296 23.8914 4.07213L22.9427 2.09751C22.6716 1.53604 22.0134 1.28435 21.4325 1.53604C19.8062 2.23304 18.4121 3.143 17.2699 4.22721C15.8951 5.54376 14.9466 7.03455 14.4431 8.68023C13.9397 10.3064 13.6882 12.5718 13.6882 15.4759V21.4197C13.6882 22.0586 14.2109 22.5814 14.8498 22.5814H22.4587C23.0976 22.5814 23.6203 22.0586 23.6203 21.4197V13.8109C23.6203 13.1718 23.0976 12.6492 22.4587 12.6492H18.7995C18.8382 10.6936 19.303 9.12553 20.1547 7.94432C20.8517 6.99583 21.8972 6.22139 23.3106 5.62101Z" fill="#B07C4B"/>
        </svg>
      </div>
    </div>
  </div>
                            ))}
</ExampleComponent>
                    </div>
                    <div className='col-lg-7 cons-sec'>
                    <ExampleComponent>

                        <div className='text-center'>
                            <h6 className="elementor-heading-title elementor-size-default"> {wordsData?.words?.get_consultation} </h6>
                            <p> {wordsData?.words?.consultation_desc} </p>


                        </div>
                        <div className="col-lg-12">
                            <select
                                name="consult_type_id"
                                value={contact.consult_type_id}
                                onChange={handleChange}
                            >
                                {consData.map(item => (
                                    <option value={item.id === consData[0].id ? "" : item.id} key={item.id} disabled={item.id === consData[0].id}> {item.name} </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-input d-flex flex-column">
                                <textarea name="topic" id="" rows="4" placeholder={wordsData?.words?.message} value={contact.topic} onChange={handleChange}></textarea>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <DatePicker onChange={onChange} needConfirm={false} placeholder={[wordsData?.words?.date]} />
                            <TimePicker onChange={onChange2} needConfirm={false} placeholder={[wordsData?.words?.time]} />
                        </div>

                        <div className="col-lg-12 d-flex justify-content-end align-items-center mt-5">
                            <Button
                htmlType="submit"
                loading={submitting}
                onClick={handleConfirm}
                className="send-mesg"
              >
                {!submitting && wordsData?.words?.send}
              </Button>
                        </div>
                        </ExampleComponent>
                    </div>
                </div>

            </Container>
        </div>
}
</>
    )
}

export default Consultation
