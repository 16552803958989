import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import ExampleComponent from '../Components/Example/Example';
import underLineImg from "../Assets/title line.png"
import { DatePicker, TimePicker } from 'antd';
import axios from 'axios';
import { Button } from "antd";
import { toast } from "react-toastify";
import Loader from './Loader';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from "react-icons/bs";

function Blogs({language}) {

    const [wordsData, setWordsData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
        try {
            setLoading(true);
            const wordsResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/blogs`,
                headers: {
                    Accept: "application/json",
                    lang: language
                },
            });
            const mainResult = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/pages/services`,
              headers: {
                Accept: "application/json",
                lang: language
              },
            });
          setMainData(mainResult.data.data);
            setWordsData(wordsResult.data.data);
            setLoading(false);
        } catch (err) {
        }
    })();
}, [language]);

  return (
    <>
    {loading ? <Loader/> :
    <div className='blogs services-section m-0 p-0'>
       <div className='bg-head' style={{ backgroundImage: `url(${mainData?.images?.main_image})` }}>
           <h5 className='bg-title'>  {language === "ar" ? "المدونة" : "BLOGS"} </h5>
       </div>
       <Container>
       <div className="row my-5">

{wordsData.map(item=>(
     <div className="col-lg-4" key={item.id}>
     <div className='card'>
     <img src={item.image} alt="" style={{width:"100%",height:"100%"}}/>
     <div className='service-content'>
         <div className='sec-first'>  </div>
         <div className='sec-second'>
         <h6> {item.title} </h6>
         <p> {item.short_description} </p>
        <Link to={`/blog-details/${item.id}`}> <button>  {language === "ar" ? "تفاصيل اكثر" : "More Details"} <BsArrowLeft className='me-2 arrow'/> </button> </Link>
         </div>
         
     </div>
     </div>
 </div>
))}
                            </div>
       </Container>
   </div>
}
</>
  )
}

export default Blogs
