import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./NewPassword.css";
import { useNavigate } from "react-router-dom";
import { RiLockPasswordFill } from "react-icons/ri";
import { Button } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Pages/Loader";
import logo from "../../Assets/logo2.png";
import { RiLockUnlockFill } from "react-icons/ri";

function NewPassword({ setIsLoggedIn ,language }) {

  const [user, setUser] = useState({ code: "", password: "" });
 
  const [loading, setLoading] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const resetPassword = language === "ar" ? "إعادة تعيين كلمة السر" : "Reset Password"

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Tajawal' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/change-password`, user)
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setUser({ code:"" ,password: "" });
        localStorage.setItem("mahace-user", res?.data?.data?.access_token);
        localStorage.setItem("mahace-userName", res?.data?.data?.client?.name);
        setIsLoggedIn(localStorage.getItem("mahace-user"));
        localStorage.getItem("mahace-type") === "service" ?  navigate("/service") : navigate("/consultation") ;
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });

  };


  return (
<>
{loading ? <Loader/> :
<Container className="parent-login">
<div className="login">
<Row>

<Col md={6} className="logo-back d-flex align-items-center justify-content-center">
<img className='logo' src={logo} alt='' />
</Col>

<Col md={6}>
<div className="login-sec">
            <h5> {language === "ar" ? "كلمة المرور الجديدة" : "New Password"} </h5>
            <p> {language === "ar" ? "ادخل رمز التحقق المرسل علي بريدك الالكتروني" : "Enter the verification code sent to your email"} </p>
            <form
              className="d-flex flex-column align-items-center"
              onSubmit={handleSubmit}
            >
                  <div className="form-input">
                <span>
                  <RiLockUnlockFill />
                </span>

                <input
                  type="text"
                  placeholder={language === "ar" ? "رمز التحقق" : "verification code"}
                  value={user.code}
                  name="code"
                  onChange={handleChange}
                />
              </div>
              <div className="form-input">
                <span>
                  <RiLockPasswordFill />
                </span>

                <input
                  name="password"
                  type="password"
                  placeholder={language === "ar" ? "كلمة المرور الجديدة" : "New Password"}
                  value={user.password}
                  onChange={handleChange}
                />
              </div>
              <Button
                htmlType="submit"
                loading={submitting}
                className="btn-login mx-auto mt-4"
              >
                {!submitting && resetPassword}
                </Button>
            </form>
          </div>
</Col>
</Row>

</div>
</Container>
}
</>
  );
}

export default NewPassword
