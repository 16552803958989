import React, { useState, useEffect, useRef } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import "./NavBarr.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import axios from "axios";



function NavBarr({setIsLoggedIn ,isLoggedIn ,language , setLanguage ,socialData}) {
  const [navbar, setNavbar] = useState(false);

  const [show, setShow] = useState(false);

  const [wordsData, setWordsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleShow = () => setShow(true);

  const changeNavColor = () => {
    if (window.scrollY <= 80 && window.innerWidth >= 991) {
      setNavbar(false);
    } else {
      setNavbar(true);
    }
  };

  const collapseButtonRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    changeNavColor();
    window.addEventListener("scroll", changeNavColor);
  });

  useEffect(() => {
    (async () => {
        try {
            setLoading(true);
            const wordsResult = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_BASE_URL}/pages/navbar`,
                headers: {
                    Accept: "application/json",
                    lang: language
                },
            });
            setWordsData(wordsResult.data.data);
            setLoading(false);
        } catch (err) {
        }
    })();
}, [language]);

  const location = useLocation();

  const handleLogOut = () => {
    localStorage.removeItem("mahace-user");
    setIsLoggedIn(localStorage.getItem("mahace-user"));

  }

  const handleArabic =()=>{
    setLanguage("ar")
    localStorage.setItem("mahace-lang" ,"ar")
  }

  const handleEnglish =()=>{
    setLanguage("en")
    localStorage.setItem("mahace-lang" ,"en")
  }

  const userName = localStorage.getItem("mahace-userName");

  const handleCollapse = () => {
    if (collapseButtonRef.current) {
      collapseButtonRef.current.click(); 
    }
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 768; 
  };


    const handleMobileCollapse = () => {
      if (isMobileDevice()) {
        handleCollapse();
      }
    };

    const handleConsultationClick = () => {
      navigate('/login');
      localStorage.setItem("mahace-type" ,"consultation");
    };

    const handleServiceClick = () => {
      navigate('/login');
      localStorage.setItem("mahace-type" ,"service");
    };
  
  return (
    <Navbar expand="lg" className={navbar ? "navbar-dark" : "navbar"}>
      <Container>
        <Link className="mob-logo" to="/">
          <img className="logo" src={navbar ? socialData?.logo : socialData?.second_logo } alt="" />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" ref={collapseButtonRef}/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Link className="not-mob-logo" to="/">
            <img className="logo" src={navbar ? socialData?.logo : socialData?.second_logo } alt="" />
          </Link>
          <Nav className="d-flex justify-content-center">
            <div className="parent_links mx-2" onClick={handleMobileCollapse}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/" ? "active-link" : "links"}
                to="/"
              >
                 {wordsData?.words?.home}
              </Link>
            </div>

            <div className="parent_links mx-2" onClick={handleMobileCollapse}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/services" ? "active-link" : "links"}
                to="/services"
              >
               {wordsData?.words?.services}
              </Link>
            </div>

            <div className="parent_links mx-2" onClick={handleMobileCollapse}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/blogs" ? "active-link" : "links"}
                to="/blogs"
              >
               {language === "ar" ? "المدونة" : "BLOGS"}
              </Link>
            </div>

            <div className="parent_links mx-2" onClick={handleMobileCollapse}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/about-us" ? "active-link" : "links"}
                to="/about-us"
              >
                {wordsData?.words?.about_us}
              </Link>
            </div>
            <div className="parent_links mx-2" onClick={handleMobileCollapse}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/most-questions" ? "active-link" : "links"}
                to="/most-questions"
              >
                {wordsData?.words?.faqs}
              </Link>
            </div>
            {isLoggedIn ? <>  <div className="parent_links mx-2" onClick={handleMobileCollapse}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/consultation" ? "active-link" : "links"}
                to="/consultation"
              >
                {wordsData?.words?.consultation_request}
              </Link>
            </div> 
            <div className="parent_links mx-2" onClick={handleMobileCollapse}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/service" ? "active-link" : "links"}
                to="/service"
              >
                {language === "ar" ? "طلب خدمة" : "REQUEST A SERVICE"}
                </Link>
            </div>
            </>:   <div className="parent_links mx-2" onClick={handleMobileCollapse}>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/contact-us" ? "active-link" : "links"}
                to="/contact-us"
              >
                {wordsData?.words?.contact_us}
              </Link>
            </div>}
           
          </Nav>
          <div className="d-flex align-items-center nav-btns">
          <Dropdown className="drop-language">
            <Dropdown.Toggle className="dropdown-user" >
              <div>
                <span><svg height="20" width="20" style={{ marginTop: "-5px" }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.2948 5.44068C15.6348 8.99068 13.8448 12.9507 10.2948 14.2907C6.74483 15.6307 2.78485 13.8407 1.44485 10.2907C0.104852 6.74068 1.89484 2.78068 5.43484 1.44068C8.99484 0.110683 12.9548 1.89068 14.2948 5.44068Z" stroke="#B07C4B" strokeWidth="1.2" strokeMiterlimit="10"></path><path d="M10.4649 6.89009C11.8049 10.4401 11.7348 13.7501 10.2948 14.2901C8.86481 14.8301 6.61491 12.3901 5.27491 8.85009C3.93491 5.30009 4.00482 1.99009 5.43482 1.45009C6.88482 0.910089 9.12485 3.34009 10.4649 6.89009Z" stroke="#B07C4B" strokeWidth="1.2" strokeMiterlimit="10"></path><path d="M1.45483 10.2914C1.45483 10.2914 10.7448 8.22141 14.2948 5.44141" stroke="#B07C4B" strokeWidth="1.2" strokeMiterlimit="10"></path></svg></span>
                <span className="mx-1"> {language === "ar" ? "AR" : "EN"} </span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu id="drop" className="dropdown-menu">

                  <Dropdown.Item className="dropdown-items" onClick={handleArabic}>
                    <h6> AR </h6>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-items" onClick={handleEnglish}>
                    <h6> EN </h6>
                  </Dropdown.Item>

                </Dropdown.Menu>
          </Dropdown>
          <div>
          {isLoggedIn ?    <div className="user-sec">
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-user" >
                    <p> {language === "ar" ? "مرحبا" : "Hello"}  <span> {userName} </span> </p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu id="drop" className="dropdown-menu">
                    <Dropdown.Item className="dropdown-items">
                    <button className="create-account" onClick={handleLogOut}> <BiLogOut className="mx-1 logout-icon" /> {wordsData?.words?.logout} </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
    : <>
    <button className="create-account mx-2" onClick={handleConsultationClick}> {wordsData?.words?.send_consultation} </button>
<button className="create-account mx-2" onClick={handleServiceClick}> {language === "ar" ? "أطلب خدمتك الآن" : "REQUEST YOUR SERVICE NOW"} </button>
</>
}</div>
          </div>
       
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarr;
